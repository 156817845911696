import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby'
import Button from 'react-bootstrap/Button';

const ProjectsPage = ({data}) => {

  const items = data.allNodeProject.edges.map(({node}, index) => {
    console.log(node, index)
    return (
        <p>
          <Link to="/projects">
            <Button variant="primary">
              <p>{node.title}</p>
            </Button>
        </Link>
        </p>
    )
  })

  
  return (

    <Layout>
      <SEO title="Projects" />
        <div className="component-wrapper">
          <h1>Projects</h1>
          <div className="projects-list">
            {items}
          </div>
        </div>
    </Layout>

  )
}

export const query = graphql`
  query ProjectsPage {
    allNodeProject(filter: {status: {eq: true}}) {
      edges {
        node {
          title 
          body {
            value
          }
          relationships {
            node__project_job {
              title
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
